<script setup lang="ts">
import type { Options as SplideOptions, Splide as SplideType } from '@splidejs/splide';
import { Splide } from '@splidejs/vue-splide';

import '@splidejs/vue-splide/css';

interface SliderProps {
  disableProgress?: boolean;
  sliderOptions?: SplideOptions;
}

const props = defineProps<SliderProps>();

const defaultSliderOptions: SplideOptions = {
  drag: true,
  keyboard: true,
  type: 'slide',
  pagination: false,
  mediaQuery: 'min',
  breakpoints: {
    0: {
      perPage: 1,
      arrows: false
    },
    640: {},
    768: {},
    1280: {
      arrows: true
    },
    1536: {
      perPage: 1
    }
  },
  classes: {
    // Add classes for arrows.
    arrows: 'splide__arrows image-slider__arrows',
    arrow: 'splide__arrow image-slider__arrow',
    prev: 'splide__arrow--prev image-slider__prev',
    next: 'splide__arrow--next image-slider__next',

    // Add classes for pagination.
    pagination: 'splide__pagination image-slider__pagination', // container
    page: 'splide__pagination__page image-slider__page' // each button
  }
};

const effectiveSliderOptions = {
  ...defaultSliderOptions,
  ...props.sliderOptions
};

const splide = ref<SplideType>();
const progressStyleWidth = ref<string>('');

function updateProgress(splide: SplideType) {
  if (!props.disableProgress) {
    const end = splide.Components.Controller.getEnd() + 1;
    const rate = Math.min((splide.index + 1) / end, 1);
    progressStyleWidth.value = `width:${String(100 * rate)}%`;
  }
}
</script>

<template>
  <div class="relative">
    <Splide
      ref="splide"
      :options="effectiveSliderOptions"
      @splide:mounted="updateProgress"
      @splide:move="updateProgress"
    >
      <slot></slot>
    </Splide>
    <div
      v-if="!disableProgress"
      class="absolute bottom-0 z-10 w-full bg-black"
    >
      <div
        class="transition-width h-1 w-0 bg-primary duration-400 ease-in-out"
        :style="progressStyleWidth"
      ></div>
    </div>
  </div>
</template>
